import { useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./Navbar.css";

const Navbar = () => {
  const navigate = useNavigate();

  const onLogoContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onServicesContainerClick = useCallback(() => {
    navigate("/services");
  }, [navigate]);

  const onTechnologyContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onBlogContainerClick = useCallback(() => {
    navigate("/blog");
  }, [navigate]);
  const onCompanyContainerClick = useCallback(() => {
    navigate("/company");
  }, [navigate]);

  const onContactContainerClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="navbar">
      <div className="navbar_left">
        <img
          className="navlogo"
          onClick={onLogoContainerClick}
          alt=""
          src="./company_logo.jpg"
        />
      </div>
      <div className={`navbar_right ${isMenuOpen ? "open" : ""}`}>
        {/* <li id="li1" onClick={onTechnologyContainerClick} className="pages">
          Product
        </li>
        <li id="li1" onClick={onCompanyContainerClick} className="pages">
          Company
        </li>
        <li id="li1" onClick={onBlogContainerClick} className="pages">
          Blog
        </li>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe6et1uYy5-dQmgUVx2aUSsz9VHYmKLvg-CAIrW8Yui_FWO8g/viewform"
          target="_blank"
        >
          <li id="li1" className="pages">
            Careers
          </li>
        </a> */}

        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "pages active nav_item" : "pages nav_item")}
        >
          Product
        </NavLink>
        <NavLink
          to="/company"
          className={({ isActive }) => (isActive ? "pages active nav_item" : "pages nav_item")}
        >
          Company
        </NavLink>
        <NavLink
          to="/blog"
          className={({ isActive }) => (isActive ? "pages active nav_item" : "pages nav_item")}
        >
          Blog
        </NavLink>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSe6et1uYy5-dQmgUVx2aUSsz9VHYmKLvg-CAIrW8Yui_FWO8g/viewform"
          target="_blank"
          className="pages nav_item"
          rel="noopener noreferrer"
        >
          Careers
        </a>

        {/* <li onClick={onServicesContainerClick} className="pages">
						Services
					</li> */}
        {/* <li onClick={onContactContainerClick} className="pages">
					<button className="contact_us_button">
						<h3 className="contact_us_text">Contact us</h3>
					</button>
				</li> */}
      </div>
      <div
        className={`hamburger ${isMenuOpen ? "open" : ""}`}
        onClick={handleHamburgerClick}
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
};

export default Navbar;
