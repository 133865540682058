import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Techhero from "../components/Techhero"
import Navbar from "../components/Navbar";
import UD from "../components/Technology/UD"
import Paceb_video from "../components/Technology/Paceb-video";
import Footer from "../components/Footer";
import "./Technology.css"
import Features from "../components/Technology/Features";
import Intheworks from "../components/Technology/Intheworks";
const Technology = () => {
  const navigate = useNavigate();

 

  const onFrameContainer1Click = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='video-container']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <div className="technology">
      <Navbar/>
      <Techhero onClick={onFrameContainer1Click}/>
      <Paceb_video/>
      <div className="user-focused-design">
        <p>USER-FOCUSED DESIGN</p>
        <h1>paceB’s Software Application Boasts A Functional And User-Centric UI/UX Design</h1>
        <UD/>
      </div>
      <div className="features-container">
        <p>FEATURES</p>
        <Features/>
      </div>
      {/* <div className="features-container">
        <p>IN THE WORKS</p>
        <Intheworks/>
      </div> */}
      <Footer/>
    </div>
  );
};

export default Technology;
