import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Techhero.css";
import { useState } from "react";
const Techhero = ({ onClick }) => {
	const navigate = useNavigate();
	const onGroupContainerClick = useCallback(() => {
		navigate("/contact-us");
	}, [navigate]);

	const [mainImage, setMainImage] = useState("./shoe1.jpg");
	const handleImageChange = (newImage) => {
		setMainImage(newImage);
	};
	return (
		<div className="techbanner">
			<div className="techbanner-text">
				<h2>paceB</h2>
				<p>
				paceB, AI-Integrated Gait Analysis Device is developed with the vision of facilitating Early Detection of Complex Gait Disorders (such as Parkinson's Disease) possible. The current version of paceB provides quantitative substitution for the existing visual examination of gait, doctors and therapists perform for diagnosis and treatment.
				</p>
				{/* <div className="techbuttons">
					<button className="my-button">KNOW MORE</button>
				</div> */}
			</div>
			<div className="techimage-container">
				<img src={mainImage} alt="Main Image" />
				<div className="techsmall-images">
					<img
						src="./shoe1.jpg"
						alt="Small Image 4"
						onClick={() => handleImageChange("./shoe1.jpg")}
					/>
					<img
						src="./shoe2.jpg"
						alt="Small Image 5"
						onClick={() => handleImageChange("./shoe2.jpg")}
					/>
					<img
						src="./web_comparision.png"
						alt="Small Image 6"
						onClick={() => handleImageChange("./web_comparision.png")}
					/>
					<img
						src="./report_glance.jpg"
						alt="Small Image 7"
						onClick={() => handleImageChange("./report_glance.jpg")}
					/>
				</div>
			</div>
		</div>
	);
};
export default Techhero;
