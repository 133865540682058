import React from "react";
import Slider from "react-slick";
import styles from "./OurUsers.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function OurUsers() {
    var settings = {
        adaptiveHeight: true,
        autoplay: true,
        infinite: true,
        dots: false,
        arrows: false,
        autoplaySpeed: 1000,
        variableWidth: true,
        speed: 500,
        mobileFirst:true,
        responsive: [
            {
              breakpoint: 770,
              settings: {
                centerMode: true,
                centerPadding: '0px',
                slidesToShow: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                centerMode: true,
                centerPadding: '0px',
                slidesToShow: 1
              }
            },
        ]
    };

    return(
        <section className={styles.sponsors} >
                <p>Our Users</p>
                <Slider {...settings}>
                    <div className={styles.sponsors_div}>
                        <a href="https://www.apollohospitals.com/" target="_blank"><img className={styles.image} src="./Newimages/Apollo.png" height="140px"/></a>
                    </div>
                    <div className={styles.sponsors_div}>
                        <a href="https://brainshospital.com/" target="_blank"><img className={styles.image} src="./Newimages/Brains.jpeg" height="140px"/></a>
                    </div>
                    <div className={styles.sponsors_div}>
                        <a href="https://www.gleneagleshospitals.co.in/" target="_blank"><img className={styles.image1} src="./Newimages/Gleneagles.jpg" height="140px" width="280px"/></a>
                    </div>
                    <div className={styles.sponsors_div}>
                        <a href="https://buddhiclinic.com/" target="_blank"><img className={styles.image} src="./Newimages/Buddhi.png" height="140px"/></a>
                    </div>
                    <div className={styles.sponsors_div}>
                        <a href="https://rockfortneurocentre.com/" target="_blank"><img className={styles.image} src="./rockfort-neuro-centre.avif" height="140px"/></a>
                    </div>
                    <div className={styles.sponsors_div}>
                        <a href="https://nimhans.co.in/" target="_blank"><img className={styles.image} src="./Nimhans_logo.png" height="140px"/></a>
                    </div>
                    <div className={styles.sponsors_div}>
                        <a href="https://www.kghospital.com/" target="_blank" className={styles.display_flex}><img className={`${styles.image} ${styles.kg_hospital}`} src="./kgHospital.jpg"/></a>
                    </div>
                </Slider>
        </section>
    );
}