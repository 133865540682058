import "./TeamGr.css";

const TeamGr = () => {
    return (
        // <div className="container">
        //     <div className="row">
        //         <div className="member_box">
        //             <img className="member_img" src="./IMG_1998_Original.jpg" alt="" />
        //         </div>
        //         <div className="member_box">
        //             <img className="member_img" src="./Newimages/Venkat.jpg" alt="" />
        //         </div>
        //     </div>
        //     <div className="row">
        //         <div className="member_box">
        //             <img className="member_img" src="./Newimages/vijayashankar.png" alt="" />
        //         </div>
        //         <div className="member_box">
        //             <img className="member_img" src="./Newimages/anatharaman.jpeg" alt="" />
        //         </div>
        //         <div className="member_box">
        //             <img className="member_img" src="./rectangle-51@2x.png" alt="" />
        //         </div>
        //     </div>
        //     <div className="row">
        //         <div className="member_box">
        //             <img className="member_img" src="./Newimages/sriram.jpeg" alt="" />
        //         </div>
        //         <div className="member_box">
        //             <img className="member_img" src="./rectangle-54@2x.png" alt="" />
        //         </div>
        //     </div>
        // </div>

        //         <div class="container">
        //   <div class="row">
        //     < img className="member_img" src="./IMG_1998_Original.jpg" alt="" />
        //     < img className="member_img" src="./Newimages/Venkat.jpg" alt=""  />
        //   </div>
        //   <div class="row">
        //     < img className="member_img" src="./Newimages/vijayashankar.png" alt=""  />
        //     < img className="member_img" src="./Newimages/anatharaman.jpeg" alt=""  />
        //     < img className="member_img" src="./rectangle-51@2x.png" alt=""  />
        //   </div>
        //   <div class="row">
        //     < img className="member_img" src="./Newimages/sriram.jpeg" alt=""  />
        //     < img className="member_img" src="./rectangle-54@2x.png" alt=""  />
        //   </div>
        // </div>
        
        <div className="Teamcontainer">
            <div className="crew">
                <div className="row">
                    <div className="member_box">
                        <a
                            href="https://thescalepro.com/"
                            target="_blank"
                            className="deg0"
                        >
                            <img className="member_img" src="./Newimages/anatharaman.jpeg" alt="Anantharaman" />
                        </a>
                        <div className="member_details">
                            <div className="details_left">
                                <h3 className="member_name">R. Anantharaman</h3>
                                <p className="member_des">Advisor, Go-To-Market</p>
                            </div>
                        </div>
                    </div>

                    <div className="member_box">
                        <a
                            href="https://www.linkedin.com/in/manigandan-nagarajan-santhanakrishnan-755759b/"
                            target="_blank"
                            className="deg45"
                        >
                            <img className="member_img" src="./rectangle-51@2x.png" alt="" />
                        </a>
                        <div className="member_details">
                            <div className="details_left">
                                <h3 className="member_name">Dr. N.S. Manigandan</h3>
                                <p className="member_des">Professor, Advisor</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="member_box">
                        <a
                            href="https://www.kauveryhospital.com/doctors/chennai/neurology-neuroscience/Dr-venkatraman-karthikeayan/"
                            target="_blank"
                            className="deg135"
                        >
                            <img className="member_img" src="./Newimages/Venkat.jpg" alt="VENKATRAMAN" />
                        </a>
                        <div className="member_details">
                            <div className="details_left">
                                <h3 className="member_name">Dr. Venkatraman Karthikeayan</h3>
                                <p className="member_des">Neurologist, Kauvery Hospital Chennai</p>
                            </div>
                        </div>
                    </div>

                    <div className="member_box">
                        <a
                            href="https://www.linkedin.com/in/sathyanarayananv26/"
                            target="_blank"
                            className='center'
                        >
                            <img className="member_img" src="./sathya.png" alt="" />
                        </a>
                        <div className="member_details">
                            <div className="details_left">
                                <h3 className="member_name">V. Sathyanarayanan</h3>
                                <p className="member_des">Founder & CEO</p>
                            </div>
                        </div>
                    </div>

                    <div className="member_box">
                        <a
                            href="https://www.practo.com/chennai/therapist/Dr-veena-nayak-physiotherapist"
                            target="_blank"
                            className="deg180"
                        >
                            <img className="member_img" src="./Newimages/veena.jpg" alt="VEENA" />
                        </a>
                        <div className="member_details">
                            <div className="details_left">
                                <h3 className="member_name">Dr. Veena Nayak</h3>
                                <p className="member_des">Physiotherapist, Gleneagles Hospitals Chennai</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="member_box">
                        <a
                            href="https://www.linkedin.com/in/sundararajan/"
                            target="_blank"
                            className="deg225"
                        >
                            <img className="member_img" src="./Newimages/sriram.jpeg" alt="Sriram" />
                        </a>
                        <div className="member_details">
                            <div className="details_left">
                                <h3 className="member_name">Sriram Sundararajan</h3>
                                <p className="member_des">Advisor, GTM & Investments</p>
                            </div>
                        </div>
                    </div>
                    <div className="member_box">
                        <a
                            href="https://www.linkedin.com/in/swami-vangal-ramamurthy-1758966/"
                            target="_blank"
                            className="deg315"
                        >
                            <img className="member_img" src="./rectangle-54@2x.png" alt="" />
                        </a>
                        <div className="member_details">
                            <div className="details_left">
                                <h3 className="member_name">S. V. Ramamurthy</h3>
                                <p className="member_des">Advisor, Technical & Operations</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TeamGr;
