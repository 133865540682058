import "./Features.css";

const Features = () => {
	return (
		<div className="features">
				{/* <div className="features-item">
					<div>
						<h1>Non-invasive</h1>
						<p className="feat-desc">
							Our product uses non-invasive sensors to track your gait, making
							it a safe and affordable way to assess your gait health.
						</p>
					</div>
					<img src="10.png" alt="Logo 1" className="feat-logo" />
				</div> */}
				<div className="features-item">
					<div>
						<div className="feat-head">
						<img src="foot-angle-32.png" alt="real time logo" />
						<h1>gait disorder screening</h1>
						</div>
						<p className="feat-desc">
						Conventional visual observation based gait examination is qualitative, time-consuming and repetitive. paceB provides a quantified substitution facilitating in-depth screening of abnormalities in gait.
						</p>
					</div>
					{/* <img src="12.png" alt="Logo 1" className="feat-logo" /> */}
				</div>
				<div className="features-item">
					<div>
					<div className="feat-head">
						<img src="quantified-prog-32.png" alt="real time logo" />
						<h1>QUANTIFIED PROGNOSIS</h1>
						</div>
						<p className="feat-desc">
						paceB's allows users to compare gait analysis pre- and post- medication and treatment, facilitating quantified prognostication. This enables healthcare professionals to optimize treatment and medication thereby, improving patient outcomes.
						</p>
					</div>
					{/* <img src="13.png" alt="Logo 1" className="feat-logo" /> */}
				</div>
				<div className="features-item">
					<div>
					<div className="feat-head">
						<img src="ai-report-32.png" alt="real time logo" />
						<h1>AI-integrated reports</h1>
						</div>
						<p className="feat-desc">
						paceB's reports provide in-depth insights into different gait parameters and abnormalities. Additionally, the reports are integrated with AI to provide technical interpretation for doctors and simple interpretation for patients understanding.
						</p>
					</div>
					{/* <img src="11.png" alt="Logo 1" className="feat-logo" /> */}
				</div>
		</div>
	);
};
export default Features;
