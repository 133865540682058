import "./UD.css"
const UD = () => {
    return(
        <div className="container">
            <div className="item">
                <img src="createUser_48.png" alt="Logo 1" className="logo" />
                <p className="ud-desc">CREATE USER PROFILE</p>
            </div>
            <div className="item">
                <img src="quantify_48.png" alt="Logo 2" className="logo" />
                <p className="ud-desc">QUANTIFY PATIENT'S TREATMENT RESPONSE</p>
            </div>
            <div className="item">
                <img src="analysis_48.png" alt="Logo 3" className="logo" />
                <p className="ud-desc">PERFORM IN-DEPTH GAIT ANALYSIS</p>
            </div>
            <div className="item">
                <img src="reports_48.png" alt="Logo 4" className="logo" />
                <p className="ud-desc">GET TAILORED REPORTS : DOCTORS & PATIENTS</p>
            </div>
        </div>
    )
}
export default UD;