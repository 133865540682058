import React from 'react';
import styles from './BlogComponent.module.css';
import Card from './Card';
import Blog1 from './Blog1';
const BlogComponent = (blogid) => {
   let content = "A comprehensive, easy-to-use, and affordable solution for the diagnosis and management of gait and underlying neurological disorders."
    // if (blogid==undefined){
    //     blogid=0;
    // }
    console.log("checking",typeof(blogid),blogid);
    console.log();
    return (
        <div>
        <p className={styles.header}>Discover latest scientific findings, technical papers, and learn more about the technology utilized at bEarly!</p>
     
            <div className={styles.CardsDiv}>
                <Card blogid="1" imgSrc="./G_3.png" content={content} title={"Introducing paceb: a technical white paper study"} authors={"Sathyanarayanan V & Dasarath G"} datePublished={"14th January, 2024"} />
                {/* <div className={styles.cardContainer}>
                <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7147286208233021440" width="100%" height="100%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
               
                </div> */}
                {/* <div className="cardContainer">
                <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7147286208233021440" width="100%" height="100%" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
               
                </div>
                <Card blogid="1" imgSrc="./G_3.png" content={content} title={"Introducing paceB: a technical white paper study"} authors={"Dasarath G & Sathyanarayanan V"} datePublished={"14th January, 2024"} />
                 */}

            </div>
         
            

    </div>

    
    
    );
}
export default BlogComponent;